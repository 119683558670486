
// Import all blocks
import { Options, Vue } from "vue-class-component";
import InstructionBlock from "@/components/game/psd/InstructionBlock.vue";
import VariableBlock from "@/components/game/psd/VariableBlock.vue";
import PSDblock from "@/components/game/psd/PSDBlock.vue";
import ButtonBlock from "@/components/game/ButtonBlock.vue";
import MedalOverlay from "@/components/game/MedalOverlay.vue";
import GameView from "@/components/game/GameView.vue";
import GamePlayArea from "@/components/game/GamePlayArea.vue";
import CodeArea from "@/components/game/CodeArea.vue";
import * as types from "@/utils/Types";
import { bundleAPI, worldAPI, progressAPI } from "@/utils/apiCalls";
import { createPSDTree, getNextLevel } from "@/utils/Functions";
import * as wasm from "codeParser";
import { playSound, stopSound } from "@/utils/AudioHandler";
import QuestionButton from "@/components/QuestionButton.vue";
import router from "@/router";

// Vue options
@Options({
  data() {
    return {
      nextId: 0,
      awardAchieved: false,
      runSuccess: false,
      allowNextLevel: false,
      achievements: [],
      medal: "none",
      errorMessage: "",
      errorWord: "",
      run: false,
      psdAndCode: null,
      teacher: null,
      errorVisible: "",
      savedCode: "",
      theme: null,
      //buttons for the explanation of the game
      wikiSubjects: [],
      //Instructions
      steps: [],
      currStep: -1,
      stopFlag: false,
      showErrorPopup: false,
      psdCode: null,
      fullLoop: false,
      //Breakpoint handling
      breakPoint: false,
      breakPointList: {} as Record<string, boolean>,
      waiting: false,
      onforward: false,
      //Highlighting
      prevId: "",
      variables: [],
      highlighted: -1,
    };
  },
  beforeMount() {
    this.psdCode = this.$store.getters.getLevelSettings.allowPsd;
    this.theme = this.$store.getters.getTheme;
  },
  mounted() {
    this.updateQuestionButton();
  },
  unmounted() {
    this.$store.commit("turnPreviewOff");
  },

  computed: {
    //Determine current step in gameview
    step() {
      return this.currStep >= 0 ? this.steps[this.currStep] : undefined;
    },
  },
  methods: {
    /**
     * Highlight new position in instructions
     * @param event Id of instruction position
     */
    position(event: Event) {
      this.$refs.gamePlayArea.handleNewAt(event);
    },
    /**
     * Add a breakpoint to a block
     * @param event The click event
     */
    toggleBreakPoint: function (event: Event) {
      let target = event.target as Element;
      if (this.breakPoint && target.tagName !== "BUTTON") {
        if (
          target.classList.contains("assign") ||
          target.classList.contains("while") ||
          target.classList.contains("ifElse")
        ) {
          // In this case we need to find the parent below
          target = target.querySelector(".parent") as Element;
        } else if (!target.classList.contains("parent")) {
          // In this case we need to find the parent above
          target = target.closest(".parent") as Element;
        }
        // We need to find the id of the element
        let parent = target.parentElement as Element;
        if (parent.classList.contains("assign")) {
          this.addBreakPoint(target, parent.id);
        } else if (
          parent.classList.contains("while") ||
          parent.classList.contains("ifElse")
        ) {
          // In this case we place the breakpoint on the moment
          // the condition is checked
          let x = target.querySelector(".condition")?.firstChild as Element;
          if (x) this.addBreakPoint(target, x.id);
        }
      }
    },
    /**
     * Place a breakpoint on this block
     * @param target The block to place a breakpoint on
     * @param id The id of the block
     */
    addBreakPoint: function (target: Element, id: number) {
      let idString = id.toString();
      this.breakPointList[idString] = true;
      let x = target.lastChild as Element;
      let btn = x.querySelector(".button") as Element;
      btn.classList.remove("hidden");
    },
    /**
     * Remove a breakpoint from a block when it is clicked
     * @param event The click event
     */
    clickBreakPoint: function (event: Event) {
      let target = event.target as Element;
      if (!target.classList.contains("parent"))
        target = target.closest(".parent") as Element;
      let parent = target.parentElement as Element;
      if (parent.classList.contains("assign")) {
        this.removeBreakPoint(target, parent.id);
      } else if (
        parent.classList.contains("ifElse") ||
        parent.classList.contains("while")
      ) {
        let x = target.querySelector(".condition")?.firstChild as Element;
        if (x) this.removeBreakPoint(target, x.id);
      }
    },
    /**
     * Remove a breakpoint if it exists from this block
     * @param target The block to remove the breakpoint from
     * @param id The id of the block
     */
    removeBreakPoint: function (target: Element, id: number) {
      let idString = id.toString();
      this.breakPointList[idString] = false;
      let x = target.lastChild as Element;
      let btn = x.querySelector(".button") as Element;
      btn.classList.add("hidden");
    },
    //Get the next step with instructions
    getNextStep: function () {
      if (
        !this.stopFlag &&
        this.currStep + 1 < this.steps.length &&
        (!this.waiting || this.onforward)
      ) {
        this.currStep++;
      } else if (!(this.currStep + 1 < this.steps.length)) {
        this.fullLoop = true;
        this.stop();
      }
    },
    //Reset the game loop
    reset: function () {
      this.currStep = -1;
      this.stopFlag = false;
      this.waiting = false;
      this.onforward = false;
      this.run = false;
    },
    //Stop and reset the gameloop
    stop() {
      if (this.psdCode) this.$refs.gamePlayArea.removeHighlight(this.prevId);
      else this.highlighted = -1;
      this.prevId = "";
      stopSound("MoveTo");
      this.stopFlag = true;
      if (this.runSuccess) {
        playSound("Victory", false, 0.5);
      }
      this.run = false;
      this.onforward = false;
      this.waiting = false;
      this.currStep = -1;
      if (this.fullLoop) this.showMedal();
    },
    //Continue to next step
    getOneStep: function () {
      this.onforward = true;
    },
    //Show all information of the levels and achievements
    showMedal: function () {
      let levelId = this.$store.getters.getLevelInfo.levelId;
      // Check if there is a next level
      if (typeof getNextLevel(levelId) !== "undefined") {
        let nextLevelInfo = getNextLevel(levelId) as types.level;

        // Unlock next level in the case of success
        if (this.success) nextLevelInfo.locked = false;
        this.allowNextLevel = !nextLevelInfo.locked;
      }
      this.awardAchieved = true;
    },
    //Load the current level into the store and remove the overlay
    retry: async function () {
      let levelId = this.$store.getters.getLevelInfo.levelId;
      let bundleId = this.$store.getters.getBundle as number;
      await this.loadLevel(bundleId, levelId).then(() => {
        this.awardAchieved = false;
        this.fullLoop = false;
        this.medal = "none";
        this.runSuccess = false;
      });
    },
    //Load the next level into the store and remove the overlay
    nextLevel: async function () {
      console.log("NEXT LEVEL");
      let levelId = this.$store.getters.getLevelInfo.levelId;
      let nextLevelInfo = getNextLevel(levelId) as types.level;
      let bundleId = this.$store.getters.getBundle as number;
      await this.loadLevel(bundleId, nextLevelInfo.levelId).then(() => {
        this.awardAchieved = false;
        this.fullLoop = false;
        this.medal = "none";
        this.runSuccess = false;
        this.$store.commit("setLevelId", nextLevelInfo.levelId);
        router.push("/gameredirect");
      });
    },
    /**
     * Load a level and put it in the store
     * @param bundleId the id of the bundle to load
     * @param levelId the id of the level to load
     */
    loadLevel: async function (bundleId: number, levelId: number) {
      await bundleAPI.getLevel(bundleId, levelId);
      await bundleAPI.getLevelSettings(bundleId, levelId);
      await progressAPI.loadLevelSave(bundleId, levelId);
      await worldAPI.getWorldData(this.$store.getters.getLevelInfo.world);
    },
    //Call the parser to parse the code and run it, then put the instructions in data
    runCode: async function () {
      // If we are on a breakpoint, don't ask for new instructions, just continue the game loop
      playSound("MoveTo", true, 0.1);
      this.stopFlag = false;
      if (this.waiting) {
        this.waiting = false;
        this.onforward = false;
        this.getNextStep();
      } else {
        this.run = true;
        let ASTTree: types.AST | null = null;
        let parsed: string | null = null;
        if (this.psdCode) {
          //Call the psd parser
          parsed = createPSDTree();
        } else {
          //Call the code parser
          let input = this.$refs.codeBlock.typedCode;
          let inputSplit = input.split("\n");

          //Handle single line comments
          let newInput = [];
          for (var i = 0; i < inputSplit.length; i++) {
            if (inputSplit[i].includes("//")) {
              //Keep all in front of //
              let keep = inputSplit[i].split("//")[0];
              //Check if there was something in front
              if (keep.replace(" ", "") === "") {
                continue;
              }
              newInput.push(keep);
            } else {
              newInput.push(inputSplit[i]);
            }
          }
          var newString = newInput.join("\n");
          parsed = wasm.parseCode(newString);
          //Error handling
          if (parsed.includes("col: ")) {
            let numbers = parsed
              .replace("col:", "")
              .replace("row:", "")
              .replace(" ", "");
            let outputList = numbers.split(",");
            let output =
              this.$t("general.error.row") +
              ": " +
              outputList[0] +
              ", " +
              this.$t("general.error.col") +
              ": " +
              outputList[1];

            let errorWord = this.getWordAt(
              newString,
              this.getPosition(newInput, outputList[0], outputList[1])
            );

            this.errorWord = errorWord;
            this.errorMessage = output;
            this.errorVisible = "overlayVisible";
            return;
          }
        }
        if (this.$store.getters.getLevelInfo) {
          ASTTree = {
            levelId: this.$store.getters.getLevelInfo.levelId,
            bundleId: this.$store.getters.getBundle as number,
            compiledCode: parsed as string,
          };
        } else {
          //Mock data
          ASTTree = {
            levelId: 1,
            bundleId: 1,
            compiledCode: parsed as string,
          };
        }
        //Send code to back-end and receive instructions back
        let instructions: types.instructions | null = null;
        if (parsed)
          instructions = await worldAPI.runLevel(ASTTree as types.AST);

        if (instructions) {
          //Get if the run is successful or not
          this.runSuccess = instructions.success;
          if (this.runSuccess) {
            //Get the achieved medal
            this.medal = instructions.medal;
          }
          //Get the achievements, when this has been fixed on the backend
          this.achievements = instructions.achievements;
          //Set the instructions in the game data
          this.steps = instructions.instructions;
          //Start the game loop
          this.getNextStep();
        } else {
          //There were no instructions
          console.log("Error: Run has failed");
          //this.medal = "none"; // Geen medaille behaald
          //this.runSuccess = false; // De uitvoering was niet succesvol
          this.awardAchieved = true; 
        }
      }
    },
    /**
     * Get the position of where the parse error occured
     * @param input Parse error input
     * @param row Error row position
     * @param col Error col position
     * @returns Position of parse error
     */
    getPosition: function (input: Array<string>, row: number, col: number) {
      let position = Number(col);
      for (var i = 0; i < row - 1; i++) {
        position += input[i].length;
      }
      return position;
    },
    /** Get the word at a given position in the string
     * @param str The string from which to extract the word
     * @param pos The word index
     * @returns Word of given string position
     */
    getWordAt: function (str: string, pos: number) {
      //Perform type conversions.
      pos = Number(pos) >>> 0;

      //Search for the word's beginning and end.
      var left = str.slice(0, pos + 1).search(/\S+$/),
        right = str.slice(pos).search(/\s/);

      //The last word in the string is a special case
      if (right < 0) {
        return str.slice(left);
      }

      //Return the word, using the located bounds to extract it from the string.
      return str.slice(left, right + pos);
    },
    updateQuestionButton() {
      var neededList =
        this.$store.getters.getLevelInfo.levelData.levelInstructions.needed;
      var neededPages = [] as string[];

      for (var i = 0; i < neededList.length; i++) {
        var prop = neededList[i]["name"];
        neededPages.push(prop);
      }
      if (this.$store.getters.getLevelSettings.allowPsd) {
        //These wiki page buttons are always visible when solving a level with PSD
        this.wikiSubjects.push(
          { wikiButton: { name: this.$t("wiki.psd"), item: "psdPage" } },
          { wikiButton: { name: this.$t("wiki.drag"), item: "dragPage" } },
          { wikiButton: { name: this.$t("wiki.save"), item: "savePage" } },
          { wikiButton: { name: this.$t("wiki.test"), item: "testPage" } },
          { wikiButton: { name: this.$t("wiki.break"), item: "breakPage" } },
          { wikiButton: { name: this.$t("wiki.string"), item: "stringPage" } },
          { wikiButton: { name: this.$t("wiki.bool"), item: "boolPage" } },
          { wikiButton: { name: this.$t("wiki.data"), item: "dataPage" } },
          { wikiButton: { name: this.$t("wiki.var"), item: "varPage" } },
          { wikiButton: { name: this.$t("wiki.enum"), item: "enumPage" } },
          { wikiButton: { name: this.$t("wiki.newvar"), item: "newvarPage" } },
          { wikiButton: { name: this.$t("wiki.write"), item: "writePage" } }
        );

        //based on which buttons the student is required to use, these will be added to the question button
        if (
          neededPages.includes("add" || "subtract" || "multiply" || "divide")
        ) {
          this.wikiSubjects.push({
            wikiButton: { name: this.$t("wiki.calc"), item: "calcPage" },
          });
        }
        if (neededPages.includes("parOp")) {
          this.wikiSubjects.push({
            wikiButton: { name: this.$t("wiki.par"), item: "parPage" },
          });
        }

        if (
          neededPages.includes(
            "add" ||
              "subtract" ||
              "multiply" ||
              "divide" ||
              "mod" ||
              "power" ||
              "eq" ||
              "neq" ||
              "gt" ||
              "lt" ||
              "gte" ||
              "lte" ||
              "and" ||
              "or" ||
              "exor"
          )
        ) {
          this.wikiSubjects.push({
            wikiButton: { name: this.$t("wiki.bin"), item: "binPage" },
          });
        }

        if (neededPages.includes("assign")) {
          this.wikiSubjects.push({
            wikiButton: { name: this.$t("wiki.assi"), item: "assiPage" },
          });
        }

        if (
          neededPages.includes(
            "eq" ||
              "neq" ||
              "gt" ||
              "lt" ||
              "gte" ||
              "lte" ||
              "and" ||
              "or" ||
              "exor" ||
              "not"
          )
        ) {
          this.wikiSubjects.push({
            wikiButton: { name: this.$t("wiki.check"), item: "checkPage" },
          });
        }

        if (neededPages.includes("while" || "ifElse")) {
          this.wikiSubjects.push({
            wikiButton: { name: this.$t("wiki.condi"), item: "condiPage" },
          });
        }

        if (neededPages.includes("and")) {
          this.wikiSubjects.push({
            wikiButton: { name: this.$t("wiki.and"), item: "andPage" },
          });
        }

        if (neededPages.includes("or")) {
          this.wikiSubjects.push({
            wikiButton: { name: this.$t("wiki.or"), item: "orPage" },
          });
        }

        if (neededPages.includes("exor")) {
          this.wikiSubjects.push({
            wikiButton: { name: this.$t("wiki.exor"), item: "exorPage" },
          });
        }

        if (neededPages.includes("not")) {
          this.wikiSubjects.push({
            wikiButton: { name: this.$t("wiki.not"), item: "notPage" },
          });
        }

        if (neededPages.includes("ifElse")) {
          this.wikiSubjects.push({
            wikiButton: { name: this.$t("wiki.if"), item: "ifPage" },
          });
        }

        if (neededPages.includes("while")) {
          this.wikiSubjects.push({
            wikiButton: { name: this.$t("wiki.while"), item: "whilePage" },
          });
        }

        if (neededPages.includes("power")) {
          this.wikiSubjects.push({
            wikiButton: { name: this.$t("wiki.pow"), item: "powPage" },
          });
        }

        if (neededPages.includes("mod")) {
          this.wikiSubjects.push({
            wikiButton: { name: this.$t("wiki.mod"), item: "modPage" },
          });
        }

        if (neededPages.includes("inop")) {
          this.wikiSubjects.push({
            wikiButton: { name: this.$t("wiki.inf"), item: "infPage" },
          });
        }
      } else {
        //this is used when the student has to write code instead of the drag n drop PSD
        this.wikiSubjects.push(
          { wikiButton: { name: this.$t("wiki.save"), item: "savePage" } },
          { wikiButton: { name: this.$t("wiki.test"), item: "testPage" } },
          { wikiButton: { name: this.$t("wiki.break"), item: "breakPage" } },
          { wikiButton: { name: this.$t("wiki.string"), item: "stringPage" } },
          { wikiButton: { name: this.$t("wiki.bool"), item: "boolPage" } },
          { wikiButton: { name: this.$t("wiki.data"), item: "dataPage" } },
          { wikiButton: { name: this.$t("wiki.var"), item: "varPage" } },
          { wikiButton: { name: this.$t("wiki.enum"), item: "enumPage" } },
          { wikiButton: { name: this.$t("wiki.newvar"), item: "newvarPage" } }
        );
      }
    },
  },
  components: {
    InstructionBlock,
    VariableBlock,
    PSDblock,
    ButtonBlock,
    MedalOverlay,
    GameView,
    CodeArea,
    GamePlayArea,
    QuestionButton,
  },
})
export default class Game extends Vue {}
