
import { Options, Vue } from "vue-class-component";

@Options({
  name: "medal-overlay",
  data() {
    return {
      medalVisible: "overlayVisible",
      nextLevelVisible: "",
      text: "game.achieved",
      miliseconds: 2500,
      overlays: null,
    };
  },
  mounted() {
    this.text = this.success ? "game.achieved" : "game.failed";

    this.displayMedal();
  },
  methods: {
    displayMedal() {
      const next = () => {
        this.medalVisible = "";
        this.nextLevelVisible = "overlayVisible";
      };
      const id = setTimeout(next, this.success ? this.miliseconds : 0);
      this.overlays = [id, next];
    },
    skip() {
      const [id, next] = this.overlays;
      next();
      clearTimeout(id);
    },
  },
  props: {
    medal: String,
    success: Boolean,
    allowNextLevel: Boolean,
    achievements: Array,
  },
})
export default class MedalOverlay extends Vue {}
